<template>
    <div class="index">
        <div class="index_inner">
            <div class="index_banner">
                <img class="img" src="../assets/image/banner.jpg" alt="">
            </div>
            <router-view/>
        </div>

        <div class="index_body" v-show="!isQuiz">
            <div>
                <p class="index_body_info title" style="text-indent: 0;font-weight: bold; margin-bottom: 10px;">亲爱的会员朋友：</p>

                <p class="index_body_info">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;消防安全与每个人的生活息息相关，为了增强大家的消防安全意识，提升应对火灾等突发意外的应急能力，我们特别邀请了防灾减灾教育培训师、国家应急救援员刘金龙先生，与大家分享<span style="font-weight: bold">“认识常见消防工具及实用绳结教学”</span>主题内容，为您详细讲解家中应预备哪些实用消防工具以及如何正确使用它们。同时，现场将安排绳结教学与实践，助您了解、掌握在不同危机场景下应用恰当绳结，快速、安全地脱离困境。
                </p>

                <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
                       style='background:#ffffff;border-collapse:collapse;border:none;width:90%;margin: 0 auto'>
                    <tr>
                        <td style='border:solid rgb(176,0,22) 2.0pt'>
                            <p class=MsoNormal style='margin-top:7.8pt;margin-right:21.0pt;margin-bottom:
    0cm;margin-left:10.5pt;margin-bottom:.0001pt;line-height:20.0pt'><a
                                name="OLE_LINK2"></a><a name="OLE_LINK3"><b><span style='color:rgb(176,0,22);
    background:white'>本期线下讲座的核心内容包括：</span></b></a></p>
                            <ol>
                                <li><span style="font-weight: bold">认识常见消防工具：</span>灭火器、灭火毯、防烟面罩等常规消防工具介绍及使用技巧讲解；家庭中适合配置哪些消防工具；</li>
                                <li><span style="font-weight: bold">突发火灾时如何正确应对：</span>面对火灾，如何科学逃生以及在有余力的情况下，如何帮助或配合救援；</li>
                                <li><span style="font-weight: bold">常用绳结及相关应用场景：</span>在不同场景下，应选择哪种绳结，协助自己快速脱离险境？</li>
                            </ol>
                            <ul style="list-style: square;padding-right: 15px">
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动时间：</span></b><b><span
                                    lang=EN-US class="colored">2024年12月10日（周二）12: 00-13:30</span></b></li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>活动地点：</span></b><span>公司10.13 & 10.14会议室</span></li>
                                <li><span class="colored">本期讲座讲师：刘金龙</span>
                                    <img src="../assets/image/info.jpg" style="width: 100%; clear: both" alt=""/>
                                </li>
                                <li><b><span style='font-family:"微软雅黑",sans-serif;color:black'>特别提醒：</span></b>
                                    <ul>
                                        <li><b>本次活动为线下活动，不支持线上参与；</b></li>
                                        <li>本场限定60席，仅限会员本人参加。席位先到先得，以系统成功提交为准；</li>
                                        <li>如报名成功后，因故无法到场，请您提前联系 <a href="mailto:bjunion007f@163.com">bjunion007f@163.com</a> 取消报名，避免现场席位资源浪费。<b>同时，报名满员后，我们将开放部分候补名额，如遇会员取消报名，我们将根据候补登记顺序开放参与资格。</b>
                                        </li>
                                        <li><b>参与范围：</b>2024年10月1日前入职、目前仍在职的SAP北京分公司工会会员。</li>
                                    </ul>
                                </li>
                            </ul>
                        </td>
                    </tr>
                </table>

            </div>

            <div class="index_body_button_box">
                <button class="index_button online" @click="regist()" :disabled="isDisabled">{{ btnText }}</button>
            </div>

        </div>
        <!--      <Error v-if="isShow" @close="isShow=false" :message="message"></Error>-->
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import axios from "axios";

export default {
    name: "login",
    components: {
        // Quiz,
    },
    data() {
        return {
            isQuiz: false,
            questionLoaded: false,
            isClick: true,
            stats: {
                status: false,
                submitted: 0,
                submitted_limit: 0,
                waiting: 0,
                waiting_limit: 0
            },
        }
    },
    computed: {
        ...mapState({
            staff: state => state.staff
        }),
        isDisabled: function () {
            return !(this.stats.status === false && (this.stats.submitted < this.stats.submitted_limit || this.stats.waiting < this.stats.waiting_limit));
        },
        btnText: function () {
            if (this.stats.status) {
                return '已经报名'
            } else if (this.stats.submitted < this.stats.submitted_limit) {
                return '我要报名'
            } else if (this.stats.waiting < this.stats.waiting_limit) {
                return '报名已满，登记候补'
            } else {
                return '本场已满员'
            }
        },
        giftType: function () {
            return this.staff.giftType;
        }
    },
    watch: {
        giftType() {
            this.getStats(this.staff.giftType).then(result => {
                this.stats = result;
            })
        }
    },
    mounted() {
        // this.loadQuestion().then(() => {
        //     this.questionLoaded = true;
        // })
        this.getStats(this.staff.giftType).then(result => {
            this.stats = result;
        })
    },
    methods: {
        ...mapActions({
            'loadQuestion': 'initData',
            'getStats': 'getSubmitStats'
        }),
        regist() {
            // this.isQuiz = true

            console.log(this.isClick);
            if (this.isClick) {
                this.isClick = false
                axios.post('/submit', {score: 0, result: {}, staff: this.staff}).then(rsp => {
                    this.staff.msg = rsp.data.message;
                    this.$router.replace({name: 'result'})
                }).catch((e) => {
                    alert(e.response.data.message)
                    // this.isError = true
                    // this.message = e.response.data.message
                    this.isClick = true
                })
            }
        }
    }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../assets/mixins.styl'

.index
    width 100%
    position relative
    fontDefine()

    .index_inner
        width 750px
        height 100%

        .index_banner
            width 100%

            .img
                width 100%;
                box-shadow 10px 10px 15px rgba(0, 0, 0, 0.2);

    .index_body
        width 750px
        //height 80vh
        position absolute
        top 300px
        display flex
        flex-direction column
        align-items center

        .colored
            color: rgb(176,0,22) !important
            font-weight bold

        //justify-content space-around

        .index_body_info
            //text-indent 2em
            padding 0 55px
            margin 0 0 10px 0
            font-size 32px
            font-weight 400
            color #000
            line-height 40px
            box-sizing border-box

            &.title
                font-size 36px

        .MsoTableGrid
            font-size 32px

            ul
                padding-left 1.5em

            li
                line-height 1.3em
                margin-bottom 5pt

        .index_body_img
            width 100%
            display flex
            flex-direction column
            align-items center

            img
                width 460px
                padding 10px 0

            span
                padding 10px 0

            .orspan
                font-size 40px
                font-weight bold
                //color red
                padding 20px 0

        .index_body_info_more
            padding-bottom 60px

        .index_body_button_box
            //width 100%
            padding 31px 75px

            .index_button
                buttonSet()
</style>
